import { Organization, WithContext } from 'schema-dts';

import { getOrganization } from '@hultafors/shared/helpers';

export function useOrganization(): WithContext<Organization> {
  return getOrganization(
    'Snickers Workwear',
    'https://www.snickersworkwear.com/',
    'https://www.snickersworkwear.com/assets/gfx/logo.png',
  );
}
