/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CampaignPagePlugFragment,
  ContactPagePlugFragment,
  ContentListPagePlugFragment,
  ContentPagePlugFragment,
  FindRetailersPagePlugFragment,
  ProductPlugFragment,
  WorkwearGuidePlugFragment,
} from '../dato';

export function isCampaignPagePlug(
  item: any,
): item is CampaignPagePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'CampaignPageRecord';
}

export function isContactPagePlug(item: any): item is ContactPagePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContactPageRecord';
}

export function isContentListPagePlug(
  item: any,
): item is ContentListPagePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentListPageRecord';
}

export function isContentPagePlug(item: any): item is ContentPagePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ContentPageRecord';
}

export function isFindRetailersPagePlug(
  item: any,
): item is FindRetailersPagePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'FindRetailersPageRecord';
}

export function isProductPlug(item: any): item is ProductPlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'ProductPlugRecord';
}

export function isWorkwearGuidePlug(
  item: any,
): item is WorkwearGuidePlugFragment {
  if (!Object.prototype.hasOwnProperty.call(item, '__typename')) {
    return false;
  }
  return item.__typename === 'WorkwearGuidePlugRecord';
}
