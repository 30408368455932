import {
  createContext,
  memo,
  startTransition,
  useEffect,
  useState,
} from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ApiSettings } from '@hultafors/shared/types';

import {
  Feature,
  FeatureToggleFragment,
  FooterFragment,
  GlobalFields,
  GlobalFragment,
  MenuItemFragment,
  SizeGuideFragment,
  WorkwearGuideFragment,
} from '@hultafors/snickers/types';

export interface GlobalContextValue
  extends Omit<GlobalFields, 'featureToggle'> {
  settings: ApiSettings;
  setGlobal(value: GlobalFragment): void;
  setAllMenuItems(value: MenuItemFragment[]): void;
  setFooter(value: FooterFragment): void;
  setSizeGuide(value: SizeGuideFragment): void;
  setWorkwearGuide(value: WorkwearGuideFragment): void;
  isEnabled(feature: string): boolean;
}

export const GlobalContext = createContext<GlobalContextValue | null>(null);

export interface GlobalProviderProps {
  children?: React.ReactNode;
  value: GlobalFields;
  settings: ApiSettings;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
  value,
  settings,
}) => {
  const [global, setGlobal] = useState<GlobalFragment>(value.global);
  const [allMenuItems, setAllMenuItems] = useState<MenuItemFragment[]>(
    value.allMenuItems
  );
  const [footer, setFooter] = useState<FooterFragment>(value.footer);
  const [sizeGuide, setSizeGuide] = useState<SizeGuideFragment>(
    value.sizeGuide
  );
  const [workwearGuide, setWorkwearGuide] = useState<WorkwearGuideFragment>(
    value.workwearGuide
  );
  const [appInsights, setAppInsights] = useState<ApplicationInsights>();
  const [featureToggle] = useState<FeatureToggleFragment>(value.featureToggle);

  useEffect(() => {
    if (!appInsights && process.env['APPINSIGHTS_INSTRUMENTATIONKEY']) {
      setAppInsights(
        new ApplicationInsights({
          config: {
            instrumentationKey: process.env['APPINSIGHTS_INSTRUMENTATIONKEY'],
          },
        })
      );
    }
  }, [settings]);

  useEffect(() => {
    if (appInsights) {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, [appInsights]);

  useEffect(() => {
    if (value.allMenuItems) {
      startTransition(() => {
        setAllMenuItems(value.allMenuItems);
      });
    }
    if (value.global) {
      startTransition(() => {
        setGlobal(value.global);
      });
    }
    if (value.footer) {
      startTransition(() => {
        setFooter(value.footer);
      });
    }
    if (value.sizeGuide) {
      startTransition(() => {
        setSizeGuide(value.sizeGuide);
      });
    }
    if (value.workwearGuide) {
      startTransition(() => {
        setWorkwearGuide(value.workwearGuide);
      });
    }
  }, [value]);

  const isEnabled = (feature: Feature) => {
    return featureToggle?.[feature];
  };

  return (
    <GlobalContext.Provider
      value={{
        ...value,
        allMenuItems,
        footer,
        global,
        isEnabled,
        setAllMenuItems,
        setFooter,
        setGlobal,
        setSizeGuide,
        setWorkwearGuide,
        settings,
        sizeGuide,
        workwearGuide,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalProviderMemo = memo(GlobalProvider);
